


























































































































import { defineComponent, ref, Ref, onMounted } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import { ValidationObserver } from 'vee-validate'
import axios, { AxiosError } from 'axios'

import FormInput from '@/components/form/Input.vue'
import FormSelect from '@/components/form/Select.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import FormTextarea from '@/components/form/Textarea.vue'
import FormRecaptcha from '@/components/form/Recaptcha.vue'
import FormFeedback from '@/components/form/Feedback.vue'

import FormSideTooltip from '@/components/form/SideTooltip.vue'

import { ChromeState } from '@/inc/store/modules/chrome'
import { FirstStep, SecondStep, ThirdStep } from '@/inc/store/modules/steps'
import { push, GtmLayer, parseUTMCookies } from '@/inc/utils'
import { StorageUtils } from '@/inc/plugins/storage'

export default defineComponent({
  name: 'contact-step3',
  components: {
    FormInput,
    FormSelect,
    FormCheckbox,
    FormTextarea,
    FormRecaptcha,
    FormFeedback,
    FormSideTooltip,
    ValidationObserver,
  },
  props: ['content'],
  setup(props, ctx) {
    const { $storage } = ctx.root.$options
    const error = ref(false)
    const sending = ref(false)
    const isPartner = ctx.root.$route.name === 'partner-step3'

    const { firstStep, secondStep, thirdStep } = useGetters('steps', [
      'firstStep',
      'secondStep',
      'thirdStep',
    ]) as {
      firstStep: Ref<(storage: StorageUtils) => FirstStep>
      secondStep: Ref<(storage: StorageUtils) => SecondStep>
      thirdStep: Ref<(storage: StorageUtils) => ThirdStep>
    }

    const savedStep = ref<ThirdStep>(
      thirdStep.value($storage!) ? thirdStep.value($storage!) : {}
    )

    const { cleanSteps } = useActions('steps', ['cleanSteps']) as {
      cleanSteps: (payload: { storage: StorageUtils }) => Promise<void>
    }

    const { addThirdStep } = useActions('steps', ['addThirdStep']) as {
      addThirdStep: (payload: {
        data: ThirdStep
        storage: StorageUtils
      }) => Promise<void>
    }

    const saveStep = (value: string, name: string) => {
      savedStep.value[name] = value

      addThirdStep({
        data: savedStep.value,
        storage: $storage!,
      })
    }

    const onSubmitClick = () => {
      const el = document.querySelector('.is-invalid')

      if (!el) {
        return
      }

      const elTop = el.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: elTop - 100,
        left: 0,
        behavior: 'smooth',
      })
    }

    const onSubmit = async () => {
      sending.value = true

      const step1 = firstStep.value($storage!)
      const step2 = secondStep.value($storage!)
      const step3 = thirdStep.value($storage!)

      if (step1 === null || step2 === null) {
        console.warn('Some steps are missing…', step1, step2)
        sending.value = false
        error.value = true

        return
      }

      const { action } = props.content
      const { content } = useGetters(['content'])

      let attachments = `Attachments: <ol>${step2.attachments}</ol> Inspirations: <ol>${step2.inspirations}</ol>`

      // Append moodboard URL to attachments list if it exists
      if (step2.moodboard) {
        const lang = ctx.root.$i18n.locale
        const moodboardUrl = `${process.env.VUE_APP_DOMAIN}/${lang}/moodboard-builder/${step2.moodboard}`
        // There will only be a single moodboard but an <ol> layout is requested 🤷
        // eslint-disable-next-line max-len
        attachments = `${attachments} Moodboards: <ol><li><a href="${moodboardUrl}" target="_blank">${moodboardUrl}</a><li></ol>`
      }

      const data = {
        // General
        lang: `${ctx.root.$route.params.lang}-${process.env.VUE_APP_COUNTRY}`,
        formId: isPartner ? 'partner-matexi' : 'new-project',
        analyticsId: `id_${Math.random().toString(36).substr(2, 36)}`,
        visitor_id908712: ctx.root.$cookie.get('visitor_id908712'), // eslint-disable-line camelcase
        location: process.env.VUE_APP_DOMAIN,
        // Hubspot data
        hutk: ctx.root.$cookie.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: content.value.title,
        // Step 1
        showroom: step1.value,
        // Step 2
        message: step2.message,
        attachments,
        projectTypes: step2.categories!.value,
        moodboard: step2.moodboard,
        // Step 3
        ...step3,
        // Partner
        source: props.content.source,
        // Campaign data
        ...getCampaignData(),
      }

      console.log('[onSubmit] Sending to API... 📈', data)

      await axios
        .post(action, data)
        .then(() => {
          // Push global event to datalayer
          const layer: GtmLayer = {
            event: 'generate_lead',
            // Categories
            categoryClicked: step2.categories?.items.length || 0,
            realisationCategoryName:
              step2.categories?.items.map(item => item.sfValue) || [],
            // Inspirations
            realisationName: step2.selectedRealisation
              ? [...step2.selectedRealisation]
              : undefined,
            realisationClicked: step2.selectedRealisation?.length || 0,
            // Upload types
            itemValue: [...new Set(step2.files?.map(file => file.ext))],
            // Step 3
            showroomName: step1.value,
            postalCode: step1.zip?.toString(),
            country: step3.country || '',
            // Add campaign data
          }
          push(layer)

          // Reset form steps
          cleanSteps({ storage: $storage! })

          // Go to thank you page
          ctx.root.$router.push(props.content.next)
        })
        .catch((err: AxiosError) => {
          sending.value = false
          error.value = true

          console.error('[onSubmit] Fail in submit... ❌', err)
        })
    }

    // Get campaign info saved in cookies to add to data
    const getCampaignData = () => {
      const data: Record<string, string> = {
        ...parseUTMCookies(ctx.root.$cookie),
      }

      // Add campaign cookie to cookies fields
      // Only last LP visited will be used as attribution
      // eg: { quoteRequestAttribution: 'EOY THINK A' }
      const campaignCookie = ctx.root.$cookie.get('CAMPAIGN')

      if (campaignCookie) {
        data.quoteRequestAttribution = campaignCookie
      }

      return data
    }

    onMounted(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    })

    return {
      error,
      sending,
      isPartner,
      saveStep,
      savedStep,
      onSubmit,
      onSubmitClick,
      ...(useGetters(['chrome']) as {
        chrome: Ref<ChromeState>
      }),
    }
  },
})
