













import { defineComponent, ref, onMounted, PropType } from '@vue/composition-api'
import { ContactStep as ContactStepType } from '@/inc/types'

import ContactStep from './Step.vue'

export default defineComponent({
  name: 'contact-section',
  components: {
    ContactStep,
  },
  props: {
    content: {
      type: Array as PropType<ContactStepType[]>,
      Default: [],
    },
  },
  setup(_props, ctx) {
    const { $storage } = ctx.root.$options
    const labels = ref<string[]>([])
    let showroom: string
    let category: string

    onMounted(() => {
      showroom = $storage!.getItem('firstStep')
        ? $storage!.getItem('firstStep').title
        : ''

      if (
        $storage!.getItem('secondStep') &&
        $storage!.getItem('secondStep').categories &&
        $storage!.getItem('secondStep').categories.items.length
      ) {
        const { categories } = $storage!.getItem('secondStep')

        category =
          categories.items.length > 1
            ? `${categories.items[0].label} +${categories.items.length - 1}`
            : categories.items[0].label
      } else {
        category = ''
      }

      labels.value = [showroom!, category!]
    })

    return {
      labels,
    }
  },
})
