




























































































































/* eslint-disable no-undef */
import {
  defineComponent,
  // Watch,
  onMounted,
  ref,
} from '@vue/composition-api'
import { Showroom } from '@/inc/types'
import { useActions } from '@u3u/vue-hooks'
import { FirstStep } from '@/inc/store/modules/steps'
import { push, GtmLayer } from '@/inc/utils'
import { StorageUtils } from '@/inc/plugins/storage'

/* eslint-enable camelcase */

export default defineComponent({
  name: 'contact-showroom',
  props: {
    content: {
      type: Object as () => Showroom,
      required: true,
    },
    next: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },

  setup(props, ctx) {
    const el = ref<HTMLElement | null>(null)
    const { addFirstStep } = useActions('steps', ['addFirstStep']) as {
      addFirstStep: (payload: {
        data: FirstStep
        storage: StorageUtils
      }) => Promise<void>
    }

    const nextStep = () => {
      const { value, address, title } = props.content
      const { zip } = address
      const showroom = {
        zip,
        value,
        title,
      }

      addFirstStep({
        data: showroom,
        storage: ctx.root.$options.$storage as StorageUtils,
      })

      // Track first step
      push({
        event: 'generate_lead_step1',
        showroomName: value,
      })
    }

    const pushAddress = () => {
      const { address, value } = props.content
      const { street, number, zip, city, country } = address

      const layer: GtmLayer = {
        event: 'click_showroom',
        clickType: 'address',
        clickTypeValue: `${street} ${number}, ${zip} ${city}, ${country}`,
        showroomName: value,
      }
      push(layer)
    }

    onMounted(() => {
      el.value?.classList.add('is-visible')
    })

    return {
      el,
      nextStep,
      pushAddress,
    }
  },
})
