

































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { ContactLocation, Showroom } from '@/inc/types'

import ContactShowroom from './Showroom.vue'

export default defineComponent({
  name: 'contact-section',

  props: {
    content: {
      type: Object as () => ContactLocation,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { $storage } = ctx.root.$options
    const status = ref<'nearest' | 'selected' | 'all'>('all')
    const selectedShowroom = ref<Showroom | null>(null)
    const nearestShowrooms = ref<Showroom[] | null>(null)

    const showroomsToDisplay = computed(() => {
      let results = nearestShowrooms.value
        ? nearestShowrooms.value
        : [..._props.content.items]

      if (selectedShowroom.value) {
        const index = results.indexOf(selectedShowroom.value)
        results = results.filter((r, i) => i !== index)
      }

      switch (status.value) {
        default:
          return results
        case 'nearest':
          return results.filter((r, i) => i < 3)
      }
    })

    const setShowroom = (showroom: Showroom) => {
      selectedShowroom.value = showroom
      status.value = 'selected'

      setTimeout(() => {
        document.querySelector('.contact-location__results')!.scrollIntoView({
          behavior: 'smooth',
        })
      }, 300)
    }

    const setNearestShowrooms = (showrooms: Showroom[]) => {
      status.value = 'nearest'
      nearestShowrooms.value = showrooms
      selectedShowroom.value = null
    }

    const showAll = () => {
      status.value = 'all'
    }

    onMounted(() => {
      if ($storage!.getItem('firstStep')) {
        selectedShowroom.value = _props.content.items.find(
          s => s.value === $storage!.getItem('firstStep').value
        ) as Showroom | null

        status.value = 'selected'
      }
    })

    return {
      selectedShowroom,
      showroomsToDisplay,
      nearestShowrooms,
      status,
      setShowroom,
      setNearestShowrooms,
      showAll,
    }
  },

  components: {
    ContactShowroom,
  },
})
