var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"contact-step2",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('h2',{staticClass:"contact-step2__title h3 mt-s",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_c('form',{ref:"form",staticClass:"form mt-m",attrs:{"method":"POST","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-inner"},[_c('fieldset',{staticClass:"form__fieldset"},[_vm._l((_vm.content.inputs),function(item,index){return _c('div',{key:("form-input-" + index),staticClass:"form__input",class:item.slug},[(item.slug == 'select')?_c('form-multiple-select',{ref:"multipleSelect",refInFor:true,attrs:{"name":item.name,"options":item.options,"label":item.label,"buttonLabel":item.buttonLabel,"required":item.required,"placeholder":item.placeholder,"value":_vm.savedStep && _vm.savedStep.categories
                ? _vm.savedStep.categories.items
                : []},on:{"input":_vm.saveStep}}):_vm._e(),(item.slug == 'textarea')?_c('form-textarea',{attrs:{"name":item.name,"label":item.label,"placeholder":item.placeholder,"required":item.required,"data":_vm.savedStep ? _vm.savedStep[item.name] : null},on:{"change":_vm.saveStep}}):_vm._e(),(item.tooltip)?_c('FormSideTooltip',{attrs:{"content":item.tooltip}}):_vm._e()],1)}),_c('div',{staticClass:"form__input"},[_c('FormFiles',{staticClass:"form__files form__input",attrs:{"files":_vm.savedFiles,"data":_vm.content.uploads},on:{"upload:start":_vm.onUploadStart,"upload:done":_vm.onUploadDone}}),(_vm.content.uploads.tooltip)?_c('FormSideTooltip',{attrs:{"content":_vm.content.uploads.tooltip}}):_vm._e()],1),(_vm.content.realisations)?_c('div',{staticClass:"form__input"},[_c('FormProjects',{staticClass:"form__projects form__input",attrs:{"name":"inspirations","value":_vm.savedStep ? _vm.savedStep.selectedRealisation : []}}),(_vm.content.realisations.tooltip)?_c('FormSideTooltip',{attrs:{"content":_vm.content.realisations.tooltip}}):_vm._e()],1):_vm._e(),(_vm.moodboard)?_c('Moodboard',{staticClass:"form__input",attrs:{"picture":_vm.moodboard.picture,"label":_vm.content.moodboard.label,"title":_vm.content.moodboard.title,"text":_vm.content.moodboard.htmltext}}):_vm._e()],2),(invalid)?_c('form-feedback',{staticClass:"form__feedback",attrs:{"content":"form-incomplete","icon":"alert"}}):_vm._e(),_c('fieldset',{staticClass:"form__send"},[_c('div',{staticClass:"form__send__action"},[_c('g-action',{class:{ disabled: _vm.isUploading },attrs:{"content":{
              label: _vm.content.back.label,
              icon: 'leftArrow',
              url: _vm.content.prev,
              modifiers: ['reverse', 'btn'],
            }}})],1),_c('div',{staticClass:"form__send__action"},[(_vm.isUploading)?_c('GLoadingDots',{staticClass:"form-loading"}):_vm._e(),_c('g-action',{class:{ disabled: _vm.isUploading },attrs:{"content":{
              label: _vm.content.submit.label,
              icon: _vm.content.submit.icon,
              tag: 'button',
              modifiers: ['filled', 'btn'],
            },"disabled":_vm.isUploading},nativeOn:{"click":function($event){return _vm.onSubmitClick()}}})],1)])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }