






































































import { defineComponent, ref, computed } from '@vue/composition-api'
import { SelectOption } from '@/inc/types'
import { ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules.umd.js'

extend('required', {
  ...required,
  message: 'form-required',
})

import FormCheckbox from '@/components/form/Checkbox.vue'
import FormFeedback from '@/components/form/Feedback.vue'

export default defineComponent({
  name: 'form-multiple-select',
  inheritAttrs: false,
  components: {
    FormCheckbox,
    FormFeedback,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    options: {
      type: Array as () => SelectOption[],
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Array as () => SelectOption[],
      required: false,
    },
  },
  setup(_props, ctx) {
    const el = ref<HTMLElement | null>(null)
    const isOpen = ref(false)
    const interacted = ref(false)
    const selection = ref<SelectOption[]>(_props.value ? _props.value : [])

    const toggle = (e: Event) => {
      e.stopPropagation()

      if (isOpen.value) {
        isOpen.value = false

        el.value!.querySelector('input')!.focus()
      } else {
        open()
      }
    }

    const open = () => {
      isOpen.value = true

      document.addEventListener('click', close)
      document.addEventListener('keyup', close)
    }

    const close = (e: Event | MouseEvent | KeyboardEvent) => {
      if ((e as KeyboardEvent).key && (e as KeyboardEvent).key !== 'Escape') {
        return
      }

      if (
        !(e as KeyboardEvent).key &&
        el.value!.contains(e.target as HTMLElement)
      ) {
        return
      }

      isOpen.value = false
      document.removeEventListener('keyup', close)
      document.removeEventListener('click', close)
    }

    const handleOption = (option: SelectOption, checked: boolean) => {
      const optionInArray = selection.value.find(
        o => o.sfValue === option.sfValue
      )

      if (checked) {
        if (!optionInArray) {
          selection.value.push(option)
        }
      } else if (optionInArray) {
        const index = selection.value.indexOf(optionInArray)

        selection.value.splice(index, 1)
      }

      interacted.value = true

      ctx.emit('input', result.value)
    }

    const result = computed(() => {
      if (!selection.value.length) {
        return ''
      }

      const valueArray = selection.value.map(value => value.sfValue)

      return valueArray.join('; ')
    })

    return {
      el,
      interacted,
      isOpen,
      toggle,
      open,
      close,
      selection,
      result,
      handleOption,
    }
  },
})
