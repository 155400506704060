







































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { ValidationProvider, setInteractionMode, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules.umd.js'
import { FormOverlay } from '@/inc/types'

import FormFeedback from '@/components/form/Feedback.vue'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'form-required',
})

export default defineComponent({
  name: 'Projects',
  inheritAttrs: false,
  components: {
    ValidationProvider,
    FormFeedback,
  },
  props: {
    value: {
      type: Array as () => string[],
      default: () => [],
    },
  },
  setup(props, ctx) {
    const { content } = useGetters(['content'])

    const onInput = e => {
      ctx.emit('input', e.target.value)
    }

    // TODO: récupérer la sélection depuis le store
    const selectedProjects = ref<string[]>(props.value)

    const popupContent = ref<FormOverlay | Record<string, unknown> | null>(null)

    const openPopup = () => {
      popupContent.value = content.value.step2.realisations.overlay
    }

    const closePopup = () => {
      popupContent.value = null
      ctx.root.$ee.$emit('selection:update', selectedProjects.value)
    }

    // TODO: ceci deviendra obsolète
    const updateSelection = (selection: string[]) => {
      selectedProjects.value = selection
    }

    return {
      selectedProjects,
      onInput,
      popupContent,
      openPopup,
      closePopup,
      content,
      updateSelection,
    }
  },
})
