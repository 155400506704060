

































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import { ValidationObserver } from 'vee-validate'

import { File, MoodboardBuilderPreviewContent } from '@/inc/types'
import { SecondStep } from '@/inc/store/modules/steps'
import { StorageUtils } from '@/inc/plugins/storage'

import FormFeedback from '@/components/form/Feedback.vue'
import FormMultipleSelect from '@/components/form/MultipleSelect.vue'
import FormTextarea from '@/components/form/Textarea.vue'
import FormProjects from '@/components/form/Projects.vue'
import FormFiles from '@/components/form/Files.vue'
import Moodboard from '@/components/contact/Moodboard.vue'
import FormSideTooltip from '@/components/form/SideTooltip.vue'
import { getApiUrl } from '@/inc/app.config'
import { fetch } from '@/inc/utils/cache'
import { push } from '@/inc/utils'

export default defineComponent({
  name: 'contact-step2',
  components: {
    FormFeedback,
    FormMultipleSelect,
    FormTextarea,
    FormProjects,
    FormFiles,
    FormSideTooltip,
    ValidationObserver,
    Moodboard,
  },
  props: ['content'],
  setup(_props, ctx) {
    const { $storage } = ctx.root.$options

    const form = ref<HTMLFormElement | null>(null)
    const isUploading = ref(false)
    const multipleSelect = ref<InstanceType<typeof FormMultipleSelect>>()

    const { firstStep, secondStep } = useGetters('steps', [
      'firstStep',
      'secondStep',
    ])
    const savedStep = ref<SecondStep>(
      $storage?.getItem('secondStep') ? $storage.getItem('secondStep') : {}
    )
    const savedFiles = ref<File[]>(
      savedStep.value?.files ? (savedStep.value.files as File[]) : []
    )

    let updatedFiles: File[] = []
    let projectSelection: string[] | undefined =
      savedStep.value.selectedRealisation

    const moodboard = ref<MoodboardBuilderPreviewContent['moodboard'] | null>()

    const { addSecondStep } = useActions('steps', ['addSecondStep']) as {
      addSecondStep: (payload: {
        data: SecondStep
        storage: StorageUtils
      }) => Promise<void>
    }

    const convertFilesToLinks = (files: File[]) => {
      let result = ''

      if (files && files.length) {
        files.forEach(file => {
          result += `<li><a href="${file.url}" target="_blank">${file.savedName}</a></li>`
        })
      }

      return result
    }

    const convertSelectionToLinks = (selection: string[]) => {
      let result = ''

      if (selection && selection.length) {
        selection.forEach(project => {
          result += `<li><a href="${process.env.VUE_APP_DOMAIN}${project}" target="_blank">${project}</a></li>`
        })
      }

      return result
    }

    const saveStep = () => {
      // eslint-disable-next-line prefer-destructuring
      const categories = multipleSelect.value![0]
      const message = (
        form.value!.querySelector('.form-textarea') as HTMLTextAreaElement
      ).value
      const files =
        !updatedFiles.length && savedStep.value
          ? savedStep.value!.files
          : updatedFiles

      const data: SecondStep = {
        categories: {
          items: categories ? categories.selection : [],
          value: categories ? categories.result : null,
        },
        message,
        files,
        attachments: convertFilesToLinks(files as File[]),
        selectedRealisation: projectSelection,
        inspirations: convertSelectionToLinks(projectSelection as string[]),
        moodboard: moodboard.value?.code,
      }

      addSecondStep({
        data,
        storage: $storage as StorageUtils,
      })

      console.log('✅ Step 2:', data)
    }

    const onSelectionUpdate = (payload: string[]) => {
      projectSelection = payload

      ctx.root.$nextTick(() => saveStep())
    }

    const onUploadStart = () => (isUploading.value = true)

    const onUploadDone = (payload: File[]) => {
      if (payload) {
        updatedFiles = payload
      }

      saveStep()
      isUploading.value = false
    }

    const onSubmit = () => {
      saveStep()
      ctx.root.$router.push({ path: _props.content.next })
    }

    const onSubmitClick = () => {
      const el = form.value!.querySelector('.is-invalid')

      // If there is no invalid field, we can proceed
      if (!el) {
        // Track second step
        const { value: showroomName } = firstStep.value($storage!)
        const step2 = secondStep.value($storage!) as SecondStep

        push({
          event: 'generate_lead_step2',
          showroomName,
          // Categories
          categoryClicked: step2.categories?.items.length || 0,
          realisationCategoryName:
            step2.categories?.items.map(item => item.sfValue) || [],
          // Inspirations
          realisationName: step2.selectedRealisation
            ? [...step2.selectedRealisation]
            : undefined,
          realisationClicked: step2.selectedRealisation?.length || 0,
          // Upload types
          itemValue: step2.files
            ? [...new Set(step2.files.map(file => file.ext))]
            : [],
        })

        return
      }

      const elTop = el.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: elTop - 100,
        left: 0,
        behavior: 'smooth',
      })
    }

    // If we do have a moodboard query in url, try to fetch corresponding moodboard.
    // If we do have a corresponding moodboard, display a little readonly cutout letting the user
    // know that its quote is linked to moodboard.
    const fetchMoodboard = async () => {
      const moodboardCode = ctx.root.$route.query.moodboard
      if (moodboardCode) {
        const url = `${getApiUrl()}/moodboard/view/${moodboardCode}`

        try {
          const response = await fetch(ctx.root.$route.fullPath, url)

          const data =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (response?.data?.content as MoodboardBuilderPreviewContent)
              ?.moodboard

          if (data) {
            moodboard.value = data
          }
        } catch (error) {
          console.warn('No moodboard found', moodboardCode)
          console.error(error)
        }
      }
    }

    onMounted(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      ctx.root.$ee.$on('selection:update', onSelectionUpdate)

      fetchMoodboard()
    })

    return {
      form,
      isUploading,
      multipleSelect,
      savedFiles,
      savedStep,
      saveStep,
      onSubmit,
      onSubmitClick,
      onUploadStart,
      onUploadDone,
      moodboard,
    }
  },
})
