















































import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'FormSideTooltip',
  props: {
    content: {
      type: [Array, String] as PropType<string[] | string>,
      required: true,
    },
  },
  setup() {
    const isOpen = ref(false)
    const isMobile = ref(false)

    const open = () => {
      isOpen.value = true
      document.body.classList.add('no-scroll')
      window.addEventListener('keyup', onKeyUp)
    }

    const close = () => {
      isOpen.value = false
      document.body.classList.remove('no-scroll')
      window.removeEventListener('keyup', onKeyUp)
    }

    const onResize = (w: number) => {
      isMobile.value = w <= 1024

      if (isOpen.value && !isMobile.value) {
        close()
      }
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close()
      }
    }

    onMounted(() => (isMobile.value = window.innerWidth <= 1024))

    return {
      isOpen,
      isMobile,
      open,
      close,
      onResize,
    }
  },
})
